import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { OutlinedInput, InputLabel, Select, MenuItem } from "@mui/material";
import API_URL from "../../../config/api";

import { getCurrentUser } from "../../../services/auth.service";
import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";
import { useWebSocket } from "../WebSocketContext";
import { useTranslation } from "react-i18next";

function CreditadminModal({ isModalVisible, showModal, get_AllCreditAdmin }) {
  const { t } = useTranslation();
  const { sendMessage } = useWebSocket();
  const token = getCurrentUser();

  const [phone, setPhone] = useState("");
  const [credittype, setCredittype] = useState(1);
  const [amount, setAmount] = useState(0);
  const [note, setNote] = useState("");
  const [msgErr1, setMsgErr1] = useState("");

  useEffect(() => {}, []);
  const handleChange = (event) => {
    setCredittype(event.target.value);
  };

  const handleUpdateCredit = async () => {
    if (amount <= 0) {
      setMsgErr1(`${t("please_enter_number_correctly")}`);
      return;
    }
    setMsgErr1("");
    if (phone === "") {
      setMsgErr1(`${t("please_enter_username_phone")}`);
      return;
    }
    setMsgErr1("");

    await API_URL.post(`api/creditadmin/addCreditadmin`, {
      credittype: credittype,
      amount: amount,
      note: note,
      phone: phone,
      userId: token.id,
    })
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        try {
          sendMessage({ type: "refreshCredits" });
        } catch (error) {}
        showModal();
        return res.data;
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({
              message: `${t("this_username_does_exist")}`,
              type: 3,
            })
          : err.response.data.status === 401
          ? OpenNotification({
              message: `${t("amount_already_zero")}`,
              type: 3,
            })
          : OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };

  return (
    <Modal
      style={{
        top: 10,
      }}
      title={t("add_reduce_credit")}
      open={isModalVisible}
      onOk={showModal}
      onCancel={showModal}
      width={700}
      key={0}
      footer={[
        <div className=" text-center flex justify-center">
          <button
            onClick={handleUpdateCredit}
            className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-blue-900 hover:bg-blue-700 shadow-md"
          >
            {t("confirm")}
          </button>

          <div className="mx-5 md:mx-16 "></div>
          <button
            onClick={showModal}
            className="bg-transparent hover:bg-gray-100 text-black font-semibold  py-2 px-10 border border-gray-900 hover:border-transparent rounded"
          >
            {t("cancel")}
          </button>
        </div>,
      ]}
    >
      <div className="w-full py-4 ">
        <div className="w-full ">
          <InputLabel id="demo-simple-select-label">
            username / {t("phone")}
          </InputLabel>
          <div className="flex relative">
            <OutlinedInput
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
              aria-describedby="outlined-weight-helper-text"
              autoComplete="off"
              size="small"
              fullWidth
            />
          </div>
        </div>
        <div className="w-full  flex justify-start ">
          <div className="w-2/6 ">
            <InputLabel>{t("credit_types")}</InputLabel>
            <Select
              name="credittype"
              value={credittype}
              label={t("credit_types")}
              onChange={handleChange}
              size="small"
              fullWidth
            >
              <MenuItem value={1}>
                <p className="text-green-600 font-semibold">
                  + {t("increase")}
                </p>
              </MenuItem>
              <MenuItem value={2}>
                <p className="text-red-600 font-semibold">- {t("reduce")}</p>
              </MenuItem>
            </Select>
          </div>
          <div className="w-2"></div>
          <div className="w-4/6 ">
            <InputLabel id="demo-simple-select-label">{t("credit")}</InputLabel>
            <div className="flex relative">
              <OutlinedInput
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
                aria-describedby="outlined-weight-helper-text"
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                autoComplete="off"
                size="small"
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex-col justify-start ">
          <InputLabel>{t("note")}</InputLabel>

          <OutlinedInput
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md"
            autoComplete="off"
            size="small"
            maxRows={2}
          />
        </div>
      </div>
      {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
    </Modal>
  );
}

export default CreditadminModal;
