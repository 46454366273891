import React, { useState, useEffect, useCallback } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { Tooltip } from 'antd';
import imageEmpty from "../../../../image/emptyimg.png";
import hot_icon from "../../../../icon/iconhot710_583.gif";
import config from "../../../../config/configapi";
import AddGameModal from "./AddGameModal";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import API_URL from "../../../../config/api";
import OpenNotification from "../../../../Toast/OpenNotification";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SortIcon from "@mui/icons-material/Sort";
import { useTranslation } from "react-i18next";

const DraggableImageGrid = ({
  allGame,
  onOrderChange,
  isModalGame,
  showModal,
  get_AllGame,
  gameId,
  campId,
  setGameId,
  setIsModalGame,
}) => {
  const {t} = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  const [items, setItems] = useState(allGame);
  const [boxesPerRow, setBoxesPerRow] = useState(5);
  const [heightPerRow, setHeightPerRow] = useState(250);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setBoxesPerRow(windowWidth < 768 ? 3 : 6);
    setHeightPerRow(windowWidth < 768 ? 200 : 250);
  }, [windowWidth]);

  useEffect(() => {
    setItems(allGame);
  }, [allGame]);

  const onChange = useCallback(
    (sourceId, sourceIndex, targetIndex) => {
      const nextState = swap(items, sourceIndex, targetIndex);
      const updatedItems = nextState.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
      setItems(updatedItems);
      onOrderChange(updatedItems);
    },
    [items, onOrderChange]
  );

  const sortGame = (games, check) => {
    let updatedItemsSort;
    // Function สำหรับเรียงตาม percent_rtp จากมากไปน้อย และ status: 1 ขึ้นก่อน
    if (check === 1) {
      updatedItemsSort = [...games].sort((a, b) => {
        if (a.status !== b.status) return b.status - a.status;
        return b.percent_rtp - a.percent_rtp;
      });
    } else if (check === 2) {
      // Function สำหรับเรียงตาม percent_rtp จากน้อยไปมาก และ status: 1 ขึ้นก่อน
      updatedItemsSort = [...games].sort((a, b) => {
        if (a.status !== b.status) return b.status - a.status;
        return a.percent_rtp - b.percent_rtp;
      });
    } else if (check === 3) {
      // Function สำหรับเรียงตาม gamename จาก A-Z และ status: 1 ขึ้นก่อน
      updatedItemsSort = [...games].sort((a, b) => {
        if (a.status !== b.status) return b.status - a.status;
        return a.gamename.localeCompare(b.gamename);
      });
    } else {
      // Function สำหรับเรียงตาม gamename จาก Z-A และ status: 1 ขึ้นก่อน
      updatedItemsSort = [...games].sort((a, b) => {
        if (a.status !== b.status) return b.status - a.status;
        return b.gamename.localeCompare(a.gamename);
      });
    }

    const updatedItems = updatedItemsSort.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    setItems(updatedItems);
    onOrderChange(updatedItems);
  };


  const showModalGameEdit = (id) => {
    setGameId(id);
    setIsModalGame(!isModalGame);
  };
  const showDeleteConfirm = async (id, game) => {
    await Swal.fire({
      title:`${t("confirm_delete_this_game")}`,
      text: game.gamename,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("confirm_deletion")}`,
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API_URL.delete(`api/game/deleteGame/${id}`)
          .then(async () => {
            try {
              get_AllGame();
            } catch (error) {
              console.log("Failed:", error);
            }
            try {
              if (game.imagegame !== null) {
                let string = "";
                const array = game.imagegame.split("\\");
                string = "./" + array.join("/");

                await API_URL.post(`api/game/deleteimageGame`, {
                  id: null,
                  imagegameBackup: string,
                });
              }
            } catch (e) {}
            OpenNotification({ message: `${t("deleted_successfully")}`, type: 1 });
          })
          .catch((err) => {

            OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
          });
      }
    });
  };
  const getCoverColor = (cover) => {
    if (cover === 0) return "";
    if (cover === 1) return "#0e6cfb";
    return "#fb0e0e" ;
  };
  return (
    <div>
      {isModalGame ? (
        <AddGameModal
          isModalGame={isModalGame}
          showModal={showModal}
          get_AllGame={get_AllGame}
          allGame={allGame}
          gameId={gameId}
          campId={campId}
        />
      ) : (
        <></>
      )}
      <div className="border-b-2 border-gray-400 pb-1">
        <div className="flex justify-start mt-1">
          <SortIcon /> <p>{t("sort_by")}</p>
        </div>

        <div className="grid grid-cols-2 gap-1 lg:grid-cols-4 lg:gap-3 ">
          <button
            onClick={() => sortGame(items, 1)}
            className=" px-4 py-2 border border-purple-400 rounded-lg hover:bg-purple-500 hover:text-white transition duration-200 ease-in-out focus:outline-none"
          >
            {t("sort_rtp_high_to_low")}
          </button>
          <button
            onClick={() => sortGame(items, 2)}
            className=" px-4 py-2 border border-purple-400 rounded-lg hover:bg-purple-500 hover:text-white transition duration-200 ease-in-out focus:outline-none"
          >
            {t("sort_rtp_low_to_high")}
          </button>
          <button
            onClick={() => sortGame(items, 3)}
            className="px-4 py-2 border border-purple-400 rounded-lg hover:bg-purple-500 hover:text-white transition duration-200 ease-in-out focus:outline-none"
          >
            {t("name")} A - Z
          </button>
          <button
            onClick={() => sortGame(items, 4)}
            className=" px-4 py-2 border border-purple-400 rounded-lg hover:bg-purple-500 hover:text-white transition duration-200 ease-in-out focus:outline-none"
          >
            {t("name")} Z - A
          </button>
        </div>
      </div>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={boxesPerRow}
          rowHeight={heightPerRow}
          style={{
            height: `${Math.ceil(items.length / boxesPerRow) * heightPerRow}px`,
          }}
        >
          {items.map((game) => (
            <GridItem key={game.id} className="py-1">
              <div className="bg-white p-1 m-0.5 lg:p-2 lg:m-2 rounded-lg shadow cursor-move relative h-full flex flex-col">

                <div
                  className="absolute top-1 left-1 lg:top-3 lg:left-3 bg-red-500 text-white text-xs px-1 rounded-sm"
                  style={{ backgroundColor: getCoverColor(game.cover)}}
                >
                  {/* <img src={hot_icon} className="h-5 md:h-8" alt="Hot icon" /> */}
                  <p>{game.cover===0?"":game.cover===1?"NEW":"HOT"}</p>
                </div>
                <div
                  className="flex-grow flex items-center justify-center overflow-hidden"
                  style={{ height: "150px" }}
                >
                  <LazyLoadImage
                    className="w-full h-full object-contain rounded-md pointer-events-none"
                    src={
                      `${URL_HOST}${game.imagegame}` !== null
                        ? `${URL_HOST}${game.imagegame}`
                        : game.imagegame !== null
                        ? `${URL_HOST}${game.imagegame}`
                        : imageEmpty
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageEmpty;
                    }}
                    draggable="false"
                    alt={game.gamename}
                  />
                </div>
                <div className="mt-2">
                <Tooltip title={game.gamename} className="w-full text-center" >
                    <span className="text-ellipsis overflow-hidden line-clamp-1 text-black text-center text-sm lg:text-base">{game.gamename}</span>
                  </Tooltip>
                 
                  <div className="flex flex-wrap justify-between align-middle mt-1">
                    <p className="text-xs text-gray-800 font-semibold">
                      RTP {game.percent_rtp} %
                    </p>
                    <p className="text-xs">
                      {t("status")} :
                      {game.status ? (
                        <CheckCircleIcon className="text-green-600 ml-0.5 scale-75" />
                      ) : (
                        <CancelIcon className="text-red-600 ml-0.5 scale-75" />
                      )}
                    </p>
                  </div>
                  <div className="flex justify-between align-middle mt-1">
                    <IconButton
                      onClick={() => showModalGameEdit(game.id)}
                      className="my-auto flex align-middle bg-slate-100 hover:bg-slate-200 rounded-md px-2 py-0.5"
                    >
                      <CreateIcon
                        fontSize="small"
                        color="warning"
                        className="scale-75"
                      />
                    </IconButton>
                    <IconButton
                      onClick={() => showDeleteConfirm(game.id, game)}
                    >
                      <DeleteForeverIcon
                        fontSize="small"
                        color="error"
                        className="scale-75"
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </div>
  );
};

export default DraggableImageGrid;
