import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import LockIcon from "@mui/icons-material/Lock";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import ErrorMessage from "../../Toast/ErrorMessage";

import { useTranslation } from "react-i18next";
import API_URL from "../../config/api";

import Logoweb from "../../Function/Logoweb";

import LanguageSelector from "../language-selector";
import bg from "../../image/wallpaper_login_main.jpg";
import "./style.css";


function Mainsite() {
  const { t } = useTranslation();
  let { refcodeurl, tabindexurl } = useParams();

  const [phoneLogin, setPhoneLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");

  const [msgErr2, setMsgErr2] = useState("");

  const [showPassword, setShowpassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const togglePasswordVisibility = () => {
    setShowpassword(!showPassword);
    setInputType(inputType === "password" ? "text" : "password");
  };

  const userloing = async (e) => {
    e.preventDefault();
    if (phoneLogin === "") {
      setMsgErr2(`***${t("fillcompletephonenumber")}***`);
      return;
    }

    await API_URL.post(`api/people/onePeople`, {
      phone: phoneLogin,
      password: passwordLogin,
    })
      .then((res) => {
        if (window.$chatwoot) {
          window.$chatwoot.reset();
        }

        localStorage.setItem(
          "awesomeLeadsTokenId",
          JSON.stringify(res.data.user.id)
        );

        localStorage.setItem(
          "awesomeLeadsTokenUserdata",
          JSON.stringify(res.data)
        );

        window.location.href = `/`;
        // window.location.reload();
      })
      .catch((err) => {
        setMsgErr2(`${t("phoneorpasswordincorrect")}`);
      });
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <div className="w-full p-1 h-16 lg:h-20 fixed z-50 top-0 main-bg-color shadow-lg overflow-hidden bg-slate-800 border-b-4 border-slate-900 text-white">
        <div className="grid grid-cols-3 justify-between items-center my-2 md:m-2  mx-auto">
          <div className="flex  justify-start"></div>
          <div className="block lg:flex justify-center mx-auto">
            <div className="my-auto  text-center   h-11 mx-2 object-contain ">
              <Logoweb />
            </div>
          </div>
          <div className="w-full  flex justify-end">
            <LanguageSelector />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="w-11/12 lg:w-3/5 m-auto  flex justify-center items-center h-screen">
            <div
              className="w-full px-1 md:px-2 bg-black rounded-lg"
              style={{ backgroundColor: "rgb(0 0 0 / 45%)" }}
            >
              <div className="text-center"></div>
              <div>
                <div className="block -mx-3">
                  <div className="w-full flex justify-center ">
                    <div className="  text-center  h-16 lg:h-32 mx-2 object-contain ">
                      <Logoweb />
                    </div>
                  </div>
                </div>

                <div>
                  <label className="text-xs font-semibold px-1 text-white">
                    {t("phone")} / username
                  </label>
                  <div className="relative mb-4">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <LocalPhoneIcon className="text-white"></LocalPhoneIcon>
                    </div>

                    <input
                      type="text"
                      className="custom-input w-full py-2 pl-10 pr-10 text-sm text-white bg-transparent border border-gray-600 rounded-full focus:outline-none focus:border-purple-500"
                      placeholder={phoneLogin}
                      onChange={(e) => setPhoneLogin(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label className="text-xs font-semibold px-1 text-white">
                    {t("password")}
                  </label>
                  <div className="relative mb-4">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <LockIcon className="text-white"></LockIcon>
                    </div>

                    <input
                      type={inputType}
                      className=" custom-input w-full py-2 pl-10 pr-10 text-sm text-white bg-transparent border border-gray-600 rounded-full focus:outline-none focus:border-purple-500"
                      placeholder={passwordLogin}
                      onChange={(e) => setPasswordLogin(e.target.value)}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <VisibilityOff className=" text-white" />
                      ) : (
                        <Visibility className=" text-white" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="py-2 w-full text-center">
                  {msgErr2 ? <ErrorMessage message={msgErr2} /> : <></>}
                </div>

                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-3">
                    <button
                      onClick={(e) => userloing(e)}
                      className="block w-full max-w-xs mx-auto bg-gradient-to-b from-purple-500 to-purple-700 hover:bg-gradient-to-t border-b-8 border-purple-600 hover:border-purple-500 text-white rounded-lg px-3 py-1 lg:py-3 font-semibold"
                    >
                      {t("login")}
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mainsite;
