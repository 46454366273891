import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { OutlinedInput, InputLabel, IconButton, Switch } from "@mui/material";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";
import apiForm from "../../../config/apiForm";
import config from "../../../config/configapi";
import API_URL from "../../../config/api";
import { useTranslation } from "react-i18next";

function AddCampModal({
  isModalVisible,
  showModal,
  get_AllCamp,
  getId,
  allCamp,
}) {
  const { t } = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  const [campname, setCampname] = useState("");
  const [status, setStatus] = useState(true);
  const [msgErr1, setMsgErr1] = useState("");

  const [imagecamp, setImagecamp] = useState({});
  const [imagePreviewcamp, setImagePreviewcamp] = useState(null);
  const [imagecampBackup, setImagecampBackup] = useState({});
  const [checkimagecamp, setCheckimagecamp] = useState(false);

  useEffect(() => {
    clearForm();

    if (getId != null) {
      const findone = allCamp.find((obj) => {
        return obj.id === getId;
      });
      setForm(findone);
    }
  }, [getId]);

  const setForm = (findone) => {
    setCampname(findone.campname);
    setStatus(findone.status === 1 ? true : false);
    setImagePreviewcamp(
      findone.imagecamp !== null ? `${URL_HOST}${findone.imagecamp}` : null
    );
    setImagecampBackup(findone.imagecamp);
  };
  const clearForm = () => {
    setCampname("");
    setStatus(true);
    setImagecamp({});
    setImagePreviewcamp(null);
    setImagecampBackup({});
    setCheckimagecamp(false);
  };

  const CreateCamp = async () => {
    if (campname === "") {
      setMsgErr1(`${t("fill_in_information_completely")}`);
      return;
    }
    setMsgErr1("");

    const formData = new FormData();
    formData.append("imagecamp", imagecamp);
    formData.append("campname", campname);
    formData.append("status", status ? 1 : 0);

    await apiForm
      .post(`api/camp/newCamp`, formData)
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        showModal();
        get_AllCamp();
        return res.data;
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({
              message: `${t("there_already_this_name")}`,
              type: 3,
            })
          : OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };
  const UpdateCamp = async () => {
    if (campname === "") {
      setMsgErr1(`${t("fill_in_information_completely")}`);
      return;
    }
    setMsgErr1("");
    try {
      if (checkimagecamp && imagecampBackup !== null) {
        let string = "";
        const array = imagecampBackup.split("\\");
        string = "./" + array.join("/");

        await API_URL.post(`api/camp/deleteimageCamp`, {
          imagecampBackup: string,
          id: getId,
        });
      }
    } catch (e) {}

    const formData = new FormData();
    formData.append("imagecamp", imagecamp);
    formData.append("id", getId);
    formData.append("campname", campname);
    formData.append("checkimagecamp", checkimagecamp);
    formData.append("status", status ? 1 : 0);

    await apiForm
      .put(`api/camp/updateCamp`, formData)
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        showModal();
        get_AllCamp();
        return res.data;
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({
              message: `${t("there_already_this_name")}`,
              type: 3,
            })
          : OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };
  const handleDelImageCamp = (e) => {
    setImagecamp({});
    setImagePreviewcamp(null);
    setCheckimagecamp(true);
  };
  const handleUploadImageCamp = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({
        message: `${t("image_size_must_not_exceed_20_mb")}`,
        type: 3,
      });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagecamp(file);
      setImagePreviewcamp(reader.result);
      setCheckimagecamp(true);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      style={{
        top: 10,
      }}
      title={getId === null ? `${t("add_game_camps")}`:`${t("edit_game_camp")}`}
      open={isModalVisible}
      onOk={showModal}
      onCancel={showModal}
      width={700}
      key={0}
      footer={[
        <div className=" text-center flex justify-center">
          {getId === null ? (
            <button
              onClick={CreateCamp}
              className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-purple-500 hover:bg-purple-800 shadow-md"
            >
              {t("save")}
            </button>
          ) : (
            <button
              onClick={UpdateCamp}
              className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-orange-500 hover:bg-orange-800 shadow-md"
            >
              {t("edit")}
            </button>
          )}

          <div className="mx-5 md:mx-16 "></div>
          <button
            onClick={showModal}
            className="bg-transparent hover:bg-gray-100 text-black font-semibold  py-2 px-10 border border-purple-500 hover:border-transparent rounded"
          >
            {t("cancel")}
          </button>
        </div>,
      ]}
    >
      <div className="w-full py-4 ">
        <div className="w-full ">
          <div className="flex justify-center mt-8">
            <div className="w-full rounded-lg shadow-xl bg-gray-50">
              <div className="m-4">
                <div>
                  <label className="inline-block mb-2 text-gray-500">
                    {t("upload_picture_game_camp")}
                  </label>
                  <IconButton onClick={handleDelImageCamp}>
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </div>

                <div className="flex items-center justify-center w-full">
                  {imagePreviewcamp ? (
                    <img
                      className="rounded-sm h-36"
                      src={imagePreviewcamp && imagePreviewcamp}
                      alt="nice2"
                    />
                  ) : (
                    <label className="flex flex-col w-full h-36 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center mt-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          {t("select_image_file")}
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg,image/webp"
                        className="opacity-0"
                        name="imagedriving"
                        onChange={handleUploadImageCamp}
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  flex justify-start mt-5">
          <div className="w-1/6 ">
            <p className="text-md mr-1 my-auto">{t("status")}</p>
            <Switch
              name="status"
              color="secondary"
              checked={status}
              onChange={(e) => {
                setStatus(!status);
              }}
            />
          </div>
          <div className="w-2"></div>
          <div className="w-5/6 ">
            <InputLabel id="demo-simple-select-label">
              {t("game_camp_name")}
            </InputLabel>
            <div className="flex relative">
              <OutlinedInput
                value={campname}
                onChange={(e) => setCampname(e.target.value)}
                className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
                aria-describedby="outlined-weight-helper-text"
                autoComplete="off"
                size="small"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
      {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
    </Modal>
  );
}

export default AddCampModal;
