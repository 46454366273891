import React, { useState, useEffect, useRef, createContext } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import { ConfigProvider, theme } from "antd";

import API_URL from "../../config/api";
import { IconButton } from "@mui/material";
import config from "../../config/configapi";

import { WebSocketUserProvider } from "./WebSocketUser";
import LanguageSelector from "../language-selector";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatwootComponent from "./ChatwootWidget";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";
import authUser from "../../services/auth-user";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import coin_icon from "../../icon/coin_icon.png";
import NumberFormat2float from "../../Function/NumberFormat2float";
import { useTranslation } from "react-i18next";
import Loading from "../../Toast/LoadingScreen";
import Camp from "./Camp/Camp";
import Game from "./Camp/Game/Game";
import bg1 from "../../image/wallpaper_login_main2.jpg";
import ChatWidget from "./ChatWidget";
import ChatWidget2 from "./ChatWidget2";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

export const DashboardContext = createContext();
function Dashboard() {
  const { t } = useTranslation();

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [loading, setLoading] = useState(false);
  const [userdata, setUserdata] = useState({});
  const URL_HOST = `${config.API_SERVER}`;

  const [facebookURL, setFacebookURL] = useState("");
  const [lineURL, setLineURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [gmailURL, setGmailURL] = useState("");

  const [idbank, setIdbank] = useState("");
  const [codebank, setCodebank] = useState("");
  const [namebank, setNamebank] = useState("");
  const [imgbank, setImgbank] = useState(null);
  const [bankdetail, setBankdetail] = useState("");

  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const [userId] = useState(
    JSON.parse(localStorage.getItem("awesomeLeadsTokenId"))
  );

  let pathname = useLocation();

  useEffect(() => {
    authUser();
    get_user();
    getWebUrl();
    window.scrollTo(0, 0);
  }, [pathname]);

  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getallweburl`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resFacebook = allweburl.find(({ name }) => name === "facebook");
          setFacebookURL(resFacebook.nameurl);
          const resLine = allweburl.find(({ name }) => name === "line");
          setLineURL(resLine.nameurl);
          const resWebsite = allweburl.find(({ name }) => name === "website");
          setWebsiteURL(resWebsite.nameurl);
          const resGmail = allweburl.find(({ name }) => name === "gmail");
          setGmailURL(resGmail.nameurl);
          const residbank = allweburl.find(({ name }) => name === "idbank");
          setIdbank(residbank.nameurl);
          const rescodebank = allweburl.find(({ name }) => name === "codebank");
          setCodebank(rescodebank.nameurl);
          const resnamebank = allweburl.find(({ name }) => name === "namebank");
          setNamebank(resnamebank.nameurl);
          const resimgbank = allweburl.find(({ name }) => name === "imgbank");
          setImgbank(URL_HOST + resimgbank.nameurl);
          const resbankdetail = allweburl.find(
            ({ name }) => name === "bankdetail"
          );
          setBankdetail(resbankdetail.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_user = async () => {
    setLoading(true);
    await API_URL.get(`api/people/oneUserdata/${userId}`)
      .then((res) => {
        const getoneuser = res.data.user;

        setUserdata(getoneuser);
      })
      .catch((err) => {
        logout();
      });
    setLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("awesomeLeadsTokenId");

    localStorage.removeItem("awesomeLeadsTokenUserdata");

    setIsLoggedOut(true);

    window.location.href = `/`;
  };

  return (
    <WebSocketUserProvider>
      <ConfigProvider
        theme={{
          algorithm: darkAlgorithm,
        }}
      >
        <DashboardContext.Provider
          value={{
            userId,
            get_user,
            userdata,
          }}
        >
          <div
            className=" min-h-screen"
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: "top right",
              backgroundSize: "cover",
              minHeight: "100vh",
              paddingBottom: "200px",
              height: "100%",
            }}
          >
            <div className="w-full p-1  fixed z-50 top-0  shadow-lg bg_nav_user text-white">
              <div className="grid grid-cols-3 justify-between items-center mb-1  mx-auto">
                <div className="flex  justify-start">
                  {loading ? (
                    <></>
                  ) : (
                    <div className="text-center mt-1 w-full lg:w-1/2">
                      <form
                        action="https://188bet.zzttyy.com/cust-login"
                        method="post"
                      >
                        <input
                          type="hidden"
                          name="url"
                          value={window.location.href}
                        />
                        <input
                          type="hidden"
                          name="username"
                          value={userdata.phone}
                        />
                        <input
                          type="hidden"
                          name="password"
                          value={userdata.password}
                        />
                        <button
                          type="submit"
                          className="updown1 flex align-middle justify-center text-xs lg:text-base font-medium text-shadow-1 w-full  py-1 bg-gradient-to-r from-blue-500 to-purple-700 hover:from-blue-600 hover:to-purple-800 text-white  rounded-md transition-transform transform-gpu  hover:shadow-lg"
                        >
                         <SportsEsportsIcon className="mr-1 shadow-md"/> <p className="my-auto">{t("enter_to_play")}</p>
                        </button>
                      </form>
                    </div>
                  )}
                </div>
                <div className="block lg:flex justify-center mx-auto">
                  <div className="my-auto  text-center  h-16 lg:h-16 mx-2 object-contain ">
                    <Logoweb />
                  </div>
                </div>
                <div className="w-full  flex justify-end">
                  <LanguageSelector />
                </div>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3">
                <div className="flex align-middle my-auto">
                  <AccountCircleIcon />
                  <p className="text-base">
                    {loading ? "" : `${userdata.username}`}
                  </p>
                </div>
                <div className="hidden lg:block"></div>
                <div className="flex justify-end align-middle">
                  <div className="px-2 py-2 bg-purple-800 rounded-md flex align-middle my-auto">
                    <img src={coin_icon} className="h-6 mr-1" />
                    <p className="text-base">
                      {t("credit")} :{" "}
                      {loading ? "" : `${NumberFormat2float(userdata.credit)}`}
                    </p>
                  </div>
                  <IconButton onClick={() => logout()}>
                    <LogoutIcon fontSize="medium" className="text-white" />
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="pt-32 px-1 md:px-2">
              {loading ? (
                <Loading />
              ) : (
                <Routes>
                  <Route path="/" element={<Camp userdata={userdata} />} />
                  {/* <Route path="/games" element={<Game />} /> */}
                  <Route
                    path="/games/:id"
                    element={<Game userdata={userdata} />}
                  ></Route>
                </Routes>
              )}
            </div>
          </div>
          {loading ? (
            <></>
          ) : (
            <ChatWidget2 userdata={userdata} isLoggedOut={isLoggedOut} />
          )}

          {/* <ChatwootComponent /> */}

          {/* <div className="bg-zinc-900"></div>
          <footer
            className="px-9 py-5 text-center bg-gray-800"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div className="flex justify-center">
                <div className="  text-center  h-10 lg:h-16 mx-2 object-contain ">
                  <Logoweb />
                </div>
                <div className="text-white my-auto">
                  <Nameweb />
                </div>
              </div>
            </div>

            <div>
              <p className="text-lg text-white font-semibold bg-slate-900 px-3 py-1 rounded-md w-3/6 lg:w-1/6 text-center mx-auto">
                contact
              </p>
            </div>

            <div className="grid grid-cols-4 w-full lg:w-2/5 mx-auto mt-2 ">
              <div>
                <a
                  className="text-center flex-col text-base font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={facebookURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social1}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    FACEBOOK
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={lineURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social2}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    LINE
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={gmailURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social4}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    GMAIL
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={websiteURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social3}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    WEBSITE
                  </p>
                </a>
              </div>
            </div>
            <div className="bg-gray-700 h-0.5 my-5 w-5/6 mx-auto"></div>

            <div className="mt-3 text-white text-xs lg:text-base pb-16 lg:pb-1">
              <p>© 2018. ALL RIGHTS RESERVED.</p>
            </div>
          </footer> */}
        </DashboardContext.Provider>
      </ConfigProvider>
    </WebSocketUserProvider>
  );
}

export default Dashboard;
