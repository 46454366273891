
import API from "../config/api";
export default async function authUser() {
  let user = null;
  try {
     user = JSON.parse(localStorage.getItem("awesomeLeadsTokenUserdata"));
     
     await API.post("/api/people/checktoken", {
      xaccesstoken: user.token,
    })
      .then((res) => {

        if (res.data.message === "Login Finish.") {

          return true;
        } else {
          localStorage.removeItem("awesomeLeadsTokenId");
          localStorage.removeItem("awesomeLeadsTokenUserdata");
          if (window.$chatwoot) {
            window.$chatwoot.reset();
          }
          window.location.href = `/`;
      
          return false;
        }
      })
      .catch((err) => {
        if (window.$chatwoot) {
          window.$chatwoot.reset();
        }
        localStorage.removeItem("awesomeLeadsTokenId");
   
        localStorage.removeItem("awesomeLeadsTokenUserdata");
        window.location.href = `/`;
      });
  } catch (error) {
    if (window.$chatwoot) {
      window.$chatwoot.reset();
    }
    localStorage.removeItem("awesomeLeadsTokenId");
    localStorage.removeItem("awesomeLeadsTokenUserdata");
    window.location.href = `/`;
  }

 

 
}
