import { useEffect } from 'react';

const ChatWidget2 = () => {
  useEffect(() => {
    // Check if the script is already added to avoid re-adding
    if (!document.getElementById('respondio__growth_tool')) {
      // Load the Respond.io widget script
      const script = document.createElement('script');
      script.id = 'respondio__growth_tool';
      script.src = 'https://cdn.respond.io/widget/widget.js?wId=1521571e-490a-4f6f-870b-a2ed60356208';
      script.async = true;

      document.body.appendChild(script);
    }

    // Cleanup the script when the component unmounts
    return () => {
      const existingScript = document.getElementById('respondio__growth_tool');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return null; // No JSX needed, the widget is added via the script
};

export default ChatWidget2;
