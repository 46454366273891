import React, { useState } from "react";


import History_Creditadmin from "./History_Creditadmin";

function MainCredit() {


  return (
    <div>
      {/* <div className="grid grid-cols-2 lg:grid-cols-5">
        <div className="flex w-full md:w-6/6 mx-auto my-1">

          <FormControl className="w-full">
            <InputLabel className="my-auto">ประเภท</InputLabel>
            <Select
              name="selectIndex"
              className="bg-white"
              size="small"
              label="ประเภท"
              defaultValue={selectIndex}
              value={selectIndex}
              onChange={(e) => {
                setSelectIndexCK("selectIndexCK", e.target.value, {
                  path: `/`,
                });
                setSelectIndex(e.target.value);
              }}
              fullWidth
            >
              <MenuItem value={1}>เครดิต admin</MenuItem>
              <MenuItem value={2}>ถอน</MenuItem>
              
            </Select>
          </FormControl>
        </div>
      </div> */}
      <div className="relative flex flex-col min-w-0 break-words  w-full mb-6  ">
        <div className="px-0.5 lg:px-1 py-1 flex-auto ">
          <div className="tab-content tab-space"style={{minHeight:"100vh"}}>
            {/* tab1 */}
            <History_Creditadmin/>
         

          </div>
        </div>
      </div>
    </div>
  );
}

export default MainCredit;
