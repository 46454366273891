import React, { useState } from "react";
import Dashboard from "./Dashboard";

import Mainsite from "./MainWebsite";
import i18nUser from './Language/i81n.js'
import { I18nextProvider } from 'react-i18next';
function Front_main() {
  const [userId] = useState(localStorage.getItem("awesomeLeadsTokenId"));
  return (
    <I18nextProvider i18n={i18nUser}>
      {!userId ? (
        <Mainsite/>
      ) : (
        <Dashboard />
      )}
    </I18nextProvider>
  );
}

export default Front_main;
